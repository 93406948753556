import { navigate } from "gatsby"
import { isBrowser } from "../../services/helper"

const FaqsHelp = () => {
    if(isBrowser()) {
        navigate(`/help`, { replace: true})
    }
    return null
}

export default FaqsHelp